import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { getThankYouPageProps } from "helpers/getThankYouPageProps";
import * as styles from "./thankYou.module.scss";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import { getImgProps } from "helpers/getImgProps";
import Layout from "components/layout";

const ThankYou = ({ data }) => {
  const { titlePartOne, titlePartTwo, subtitle, socialMediaLinks, oval } =
    getThankYouPageProps(data);
  const mediaLinks = sortArrayOfObjects(
    socialMediaLinks.map(({ name, text }, i) => {
      return (
        <div key={i} className={styles.socialMedia}>
          <a
            className="d-flex justify-content-center align-items-center"
            href={text}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </div>
      );
    })
  );
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.thankWrapper}>
          <p className={styles.title}>
            <span>{titlePartOne}</span> {titlePartTwo}
          </p>
          <p className={styles.subtitle}>{subtitle}</p>
          <div
            className={`${styles.follow} d-flex flex-column flex-lg-row justify-content-center align-items-center`}
          >
            {mediaLinks}
          </div>
        </div>
        <div className={styles.oval}>
          <GatsbyImage {...getImgProps(oval)} />
        </div>
      </div>
      <div className={styles.bottomLine}></div>
    </Layout>
  );
};

export const query = graphql`
  query ThankYouPageData {
    strapiThankYou {
      titlePartOne
      titlePartTwo
      subtitle
      oval {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      socialMediaLinks {
        name
        order
        text
      }
    }
  }
`;

export default ThankYou;
