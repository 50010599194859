export const getThankYouPageProps = ({
  strapiThankYou: {
    titlePartOne,
    titlePartTwo,
    subtitle,
    socialMediaLinks,
    oval,
  },
}) => {
  return {
    titlePartOne,
    titlePartTwo,
    subtitle,
    socialMediaLinks,
    oval,
  };
};
